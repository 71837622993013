import React from "react";
import Select from "react-select"

const dataOptions = [
    { value: undefined, label: "Select" },
    { value: 1, label: "Yes" },
    { value: 2, label: "No" },
    { value: 3, label: "Partial" },
    { value: 4, label: "See Remarks" },
    { value: 5, label: "Not Known" }
]

export const SelectWetlandsInput = (props:any) => {  
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectWetlandsInput;