import React from "react";
interface ValidationProps{
    isInvalid:boolean,
    errorMessage:string
}
export const ValidationMessage = ({isInvalid,errorMessage}:ValidationProps) => {    
    if (isInvalid){
        return (    
            <div className="invalid-feedback d-block">{errorMessage}</div>
        ) 
    }
    else{
        return(<></>)
    }

}