import React from "react";
import {
    Label,
    Row,
    Col,   
    Input  
  } from "reactstrap"
import {PropertyType,SaleType} from '../../../contracts/enums'
import {Property} from '../../../contracts/response-models/property'

import {PropertyPriceSale} from "./property-pricing-sale"
 import {PropertyPriceRent} from "./property-pricing-rent"
interface PricingStepProps {
  property:Property,
  setProperty:any;    
}
export const PricingStep = ({
  property,
  setProperty
}:PricingStepProps) => {    
  
  
    return (  
      <Row>


      <Col className="mx-auto" md="3" sm="12">
        <h3 className="mb-2 mt-2 text-center">PRICING</h3>

          {property.propertyType != PropertyType.LND &&
              (
                <>
                {property.saleType == SaleType.ForSale && 
                  (
                    <PropertyPriceSale property={property} setProperty={setProperty}/>
                  )
                }
                {property.saleType == SaleType.ForRent && 
                  (
                    <PropertyPriceRent property={property} setProperty={setProperty}/>
                  )
                }
                 </>
              ) }  
               {property.propertyType == PropertyType.LND  &&
              (
                <>
               
                 <PropertyPriceSale property={property} setProperty={setProperty}/>
                </>
              )} 
              
      </Col>         
          </Row>
    )
}
export default PricingStep;