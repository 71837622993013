import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertycomplexName = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
        const  handlecomplexNameChange = (e:any) => {        
        setProperty({...property,complexName:e.target.value});
      }

        return (     
            <>         
            <Col md="3" sm="12">
                <div className="d-inline mr-1">Complex Name</div>                
                <Input                
                value={property.complexName}                              
                onChange={handlecomplexNameChange}
                />
            </Col>           
         </>
    )
}
    