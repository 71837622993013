import React,{useState,useEffect} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
   
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import { EmbedVideo } from '../../contracts/response-models/embedvideo'
import { ToastContainer, toast } from "react-toastify";

import { saveEmbedVideoService } from '../../machines/listing-machine/services'

import { PropertyHeadInfo } from "./property-head-info-modal";
import { Redirect } from "react-router-dom";

interface PropertyIdProps {  
    property?:Property,
    toggle:any; 
    readyState:any;
    setReadyState:any;
}


export const PropertyEVModal = ({ property,toggle,readyState,setReadyState}:PropertyIdProps) => {  
  const [isSavedSuccess, setSavedSuccess] = useState(false); 
  const [loading, setLoading] = useState(false);
  const [embedvideo, setEmbedVideo] = useState<EmbedVideo>(
    { embededVideo:property?.embedVideo}
  );

  const handleEmbedVideoChange = (e: any) => {
    setEmbedVideo({ ...embedvideo, embededVideo: e.target.value });
  }

  const saveEmbedVideo = async (e: any) => {
    e.preventDefault()
    var isValidated = true;

    if (isValidated == true) {
        setLoading(true);
  
        const response = await saveEmbedVideoService(embedvideo, property?.id);
        toast.success("Embed Video Saved Successfully", {
          position: toast.POSITION.TOP_RIGHT
        })
  
        setLoading(false);
        toggle();
        setReadyState(true);      
      }

  }

  
  
        return (     
          <>   
          <ModalHeader toggle={toggle}  >
            Embed Video
          </ModalHeader>
          <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
            <>
                <PropertyHeadInfo property={property}    />
 
              <FormGroup>
                <Label for="address">Place video embed code here</Label>
                <textarea                  
                  name="embedvideo"
                  className="form-control"
                  id="aboutMe"
                  value={embedvideo.embededVideo}
                  placeholder="Embed Video Code"                 
                  onChange={handleEmbedVideoChange}
                ></textarea>

              </FormGroup>
              <FormGroup>

                <Row className="mb-2 mt-0">
                  <Col md="4" sm="6" className="text-right">

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1 mb-1"
                      onClick={saveEmbedVideo}

                    >
                      Save
                </Button>
                  </Col>
                  <Col md="4" sm="6" className="text-right">
                  </Col>
                </Row>

              </FormGroup>
            </>
          )}
      </ModalBody>           
         </>
    )
}
    