import React,{useEffect, useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "..//InputStyles"
import classnames from "classnames";
import Checkbox from "../checkbox/CheckboxesVuexy"
import { Check } from "react-feather"
import {Property} from '../../../contracts/response-models/property'

import {listWaterfrontService} from "../../../machines/listing-machine/services"
import { Waterfront } from "../../../contracts/response-models/waterfront";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyWaterFront = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const [waterfronts, setWaterfronts] = React.useState<Waterfront[]>([]);

    useEffect(() => {    
        async function loadWaterfronts() {
            const waterfronts = await listWaterfrontService();    
            setWaterfronts(waterfronts);              
        }
        loadWaterfronts();
    },[]);

    const  handleWaterfrontsChange = (e:any) => {  
       
        var emptyArr = new Array<Waterfront>()
        var arrWaterfronts = property.waterfronts == undefined ? emptyArr : property.waterfronts;
 
        const selectedWaterfront = waterfronts.find(element => element.id== parseInt(e.target.value)) ;
        if(e.target.checked && selectedWaterfront){  
          
            arrWaterfronts.push(selectedWaterfront);
             setProperty({...property,waterfronts:arrWaterfronts});
         }
         else{        
             const found = arrWaterfronts.find(element => element.id==selectedWaterfront?.id);          
             if(found)     {
                 const foundIndex=arrWaterfronts.indexOf(found,0);
                 arrWaterfronts.splice(foundIndex,1);
                 setProperty({...property,waterfronts:arrWaterfronts});
             }         
         } 
 
      }     
      const SetDefaultChecked=(wf:Waterfront)=>{        
        //  console.log(`property.waterfronts:${property.waterfronts?.length},wf:${wf.id}`) 
        var arrWaterfronts =  property.waterfronts;  
        if(arrWaterfronts!=undefined){    
            const found = property.waterfronts?.find(element => element.id==wf.id);          
            return found!=undefined;      
        } 
        return false;
      }
        return (     
            <>        
           <Row className="mt-2">  
           <Col md="12" sm="12">
           <h4 className="mb-3 mt-2">WATERFRONT</h4>
               </Col>
           {waterfronts.map(function(object, i){
                    return (
                        <Col md="4" sm="12">
                            <div className="d-inline-block mr-1">
                            <Checkbox color="primary" value={object.id}   onChange={handleWaterfrontsChange} icon={<Check className="vx-icon" size={16} />} label={object.title}  defaultChecked={SetDefaultChecked(object)} />
                       </div>
                        </Col>
                        );
            })}                     
                        
            </Row>     
         </>
    )
}
    