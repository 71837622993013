import React from "react";
import PropTypes from "prop-types";
import { Route} from "react-router-dom";

import {EventData,State} from 'xstate';

import {EventTypes,Events} from "../machines/login/events"
import {Context} from "../machines/login/state-context";

interface IProps {
  path?:any,
  exact?:any,
  component: any,
  state?: State<Context, Events, any, any>;
  send?:(event:EventTypes,payload?:EventData|undefined)=>{}
  // any other props that come into the component
}

export default function PublicRoute({
  component: Component,   
  state,
  send,   
  ...props
}:IProps) {  

  return (
    <Route
      {...props}
      render={props => (        
          <Component {...props} state={state} send={send}/>        
      )}
    />
  );
}

