import React from "react";
import Select from "react-select"

const dataOptions = [
    { value: undefined, label: "Select" },
    { value: 1, label: "None" },
    { value: 2, label: "Doorman" },
    { value: 3, label: "Intercom" }
]

export const SelectSecurityInput = (props:any) => {  
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectSecurityInput;