import React from "react";
import Select from "react-select"

const dataOptions = [
    { value: undefined, label: "Select One" },
    { value: 1, label: "Mint" },
    { value: 2, label: "Partially Renovated" },
    { value: 3, label: "Good Condition" },
    { value: 4, label: "Needs Some TLC" },
    { value: 5, label: "Needs Major TLC" } 
]

export const SelectConditionInput = (props:any) => {  
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectConditionInput;