import React from "react";
import { Navbar } from "reactstrap"
import classnames from "classnames"
import SidebarHeader from "./SidebarHeader"
import SideMenuContent from "../sidemenu/SideMenuContent"

interface propsSidebar{
    activePath:string;
    
}

interface stateSidebar{
    activeItem:string;
    activeIndex?:number;
}

class Sidebar extends React.Component<any> {
    static getDerivedStateFromProps(props:any, state:any) {        
        if (props.activePath !== state.activeItem) {
          return {            
            activeItem: props.activePath
          }
        }
        // Return null if the state hasn't changed
        return null
    }

    state = {
        width: window.innerWidth,
        activeIndex: null,
        hoveredMenuItem: null,
        activeItem: this.props.activePath,
        menuShadow: false    
    }

    mounted = false

    updateWidth = () => {
        if (this.mounted) {
          this.setState(prevState => ({
            width: window.innerWidth
          }))
          this.checkDevice()
        }
    }

    componentDidMount() {
        this.mounted = true
        if (this.mounted) {
          if (window != null) {
            window.addEventListener("resize", this.updateWidth, false)
          }
          this.checkDevice()
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    checkDevice = () => {
        var prefixes = " -webkit- -moz- -o- -ms- ".split(" ")
        var mq = function(query:any) {
          return window.matchMedia(query).matches
        }
            

        var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")
        return mq(query)
    }

    changeActiveIndex = (id:any) => {
        if (id !== this.state.activeIndex) {
          this.setState({
            activeIndex: id
          })
        } else {
          this.setState({
            activeIndex: null
          })
        }
    }

    handleSidebarMouseEnter = (id:any) => {
        if (id !== this.state.hoveredMenuItem) {
          this.setState({
            hoveredMenuItem: id
          })
        } else {
          this.setState({
            hoveredMenuItem: null
          })
        }
    }

    handleActiveItem = (url:any) => {
      //  console.log(window.location.pathname)
        this.setState({
          activeItem: url
        })
        
    }

    render() {
        let {
            visibilityState,
            toggleSidebarMenu,
            sidebarHover,
            toggle,
            color,
            sidebarVisibility,
            activeTheme,
            collapsed,
            activePath,
            sidebarState,
            currentLang,
            permission,
            currentUser,
            collapsedMenuPaths
        } = this.props

        let {
            menuShadow,
            activeIndex,
            hoveredMenuItem,
            activeItem            
          } = this.state

        return (
        <React.Fragment>
        <div className="menu-swipe-area d-xl-none d-block vh-100"></div>        
        <div
                className={classnames(
                  `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme}`,
                  {
                    collapsed: sidebarState === true,
                    "hide-sidebar":
                      this.state.width < 1200 && visibilityState === false
                  }
                )}
                onMouseEnter={() => sidebarHover=false}
                onMouseLeave={() => sidebarHover=true}>
            <SidebarHeader
                  toggleSidebarMenu={toggleSidebarMenu}
                  toggle={toggle}
                  sidebarBgColor={color}
                  sidebarVisibility={sidebarVisibility}
                  activeTheme={activeTheme}
                  collapsed={collapsed}
                  menuShadow={menuShadow}
                  activePath={activePath}
                  sidebarState={sidebarState}
            />
            <div className="main-menu-content overflow-hidden">
                <ul className="navigation navigation-main">
                <SideMenuContent 
                setActiveIndex={this.changeActiveIndex}
                activeIndex={activeIndex} 
                hoverIndex={hoveredMenuItem}
                handleSidebarMouseEnter={this.handleSidebarMouseEnter}
                activeItemState={activeItem}
                handleActiveItem={this.handleActiveItem}
                activePath={activePath}
                lang={currentLang}
                permission={permission}
                currentUser={currentUser}
                collapsedMenuPaths={collapsedMenuPaths}
                toggleMenu={sidebarVisibility}
                deviceWidth={this.props.deviceWidth}
                myName='Harendra'
                />                   
                </ul>
            </div>
        </div>
      </React.Fragment>
        )
    }
}

export default Sidebar
  