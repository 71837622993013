import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,   
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../../InputStyles"
import Radio from "../../../../components/@vuexy/radio/RadioVuexy"
import classnames from "classnames";
import {Property} from '../../../../contracts/response-models/property'

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyIsSecurityDeposit = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const handleIsSecurityDeposit = (e:any) =>{        
        setProperty({...property,isSecurityDeposit:e.target.value});
      }
            return (     
            <> 

                <div className="form-label-group mt-2">
            <div className="form-label-group mt-2"> Security Deposit? </div>
                    <div className="d-inline-block mr-1">
                    <Radio
                      label="Yes"
                      color="primary"
                      defaultChecked={property.isSecurityDeposit!=undefined && property.isSecurityDeposit==true}
                      name="isSecurityDeposit"
                      value={true}
                      onChange={handleIsSecurityDeposit}
                    />
                     <Radio
                      label="No"
                      color="primary"                     
                      defaultChecked={property.isSecurityDeposit!=undefined && property.isSecurityDeposit==false}
                      name="isSecurityDeposit"
                      value={false}
                      onChange={handleIsSecurityDeposit}
                    />
                    </div>                    
                   
               </div>       
              
         </>
    )
}
    