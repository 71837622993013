import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {PropertyType,SaleType} from '../../../contracts/enums'
import {Property} from '../../../contracts/response-models/property'

import {PropertyRooms} from "./listingfields/property-rooms"
import {PropertyBedrooms} from "./listingfields/property-bedrooms"
import {PropertyFullBath} from "./listingfields/property-fullbaths"
import {PropertyHalfBath} from "./listingfields/property-halfbaths"
import {PropertyPets} from  "./listingfields/property-pets"
import {PropertyGarage} from "./listingfields/property-garage"
import {PropertyYearBuilt} from "./listingfields/property-yearbuilt"
import {PropertyHeatingSource} from "./listingfields/property-heatingsource"
import {PropertyBasement} from "./listingfields/property-basement"
import {PropertyCondition} from "./listingfields/property-condition"
import {PropertyCoolingType} from "./listingfields/property-coolingtype"
import {PropertyExteriorFinish} from "./listingfields/property-exteriorfinish"
import {PropertyStyle} from "./listingfields/property-style"
import {PropertyLotSize} from "./listingfields/property-lotsize"
import {PropertyAcres} from "./listingfields/property-acres"
import { PropertycomplexName } from "./listingfields/property-complexname";
import { PropertyApproxSize } from "./listingfields/property-apprxsize";
import { PropertyParking } from "./listingfields/property-parking";
import { PropertyOwnership } from "./listingfields/property-ownership";
import { PropertyBuildingType } from "./listingfields/property-buildingtype";
import { PropertySecurity } from "./listingfields/property-security";

interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsAPT = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    

        return (     
            <>   
               <Row>
       
        <PropertyBedrooms property={property} setProperty={setProperty}/>
  
        <PropertyFullBath property={property} setProperty={setProperty}/>
  
        <PropertyHalfBath property={property} setProperty={setProperty}/>
       
        <PropertyApproxSize property={property} setProperty={setProperty} />
       
        </Row>
     
          <Row className="mt-2">   

              <PropertyCondition property={property} setProperty={setProperty} />

               <PropertyBuildingType property={property} setProperty={setProperty} />
               
               <PropertySecurity property={property} setProperty={setProperty} />

                <PropertyYearBuilt property={property} setProperty={setProperty} />
                   
                  
            </Row>

            <Row className="mt-2">

                <PropertycomplexName property={property} setProperty={setProperty} />
                <PropertyPets property={property} setProperty={setProperty}/>

                <PropertyParking property={property} setProperty={setProperty} />

                {property.saleType==SaleType.ForSale && 
                        (
                        <PropertyOwnership property={property} setProperty={setProperty} />
                        )
                    }

                    

                </Row>
         
           
         </>
    )
}
    