import React from "react";
import Select from "react-select"

const dataOptions = [
    { value: undefined, label: "Select One" },
    { value: 1, label: "Gas" },
    { value: 2, label: "Oil" },
    { value: 3, label: "Electric" },
    { value: 4, label: "Solar" },
    { value: 5, label: "Wood" }    
]
 
export const SelectHSourceInput = (props:any) => {  
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectHSourceInput;