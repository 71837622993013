import React, { PureComponent } from "react";
import classnames from "classnames";
import Sidebar from "./menu/vertical-menu/Sidebar"
import TopNavBar from "./navbar/TopNavBar";
import Footer from "./footer/Footer"

class VerticalLayout extends PureComponent<any> {
  appProps = {
    layout: "vertical", // options[String]: "vertical"(default), "horizontal"
    theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
    sidebarCollapsed: false, // options[Boolean]: true, false(default)
    navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
    navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
    footerType: "static", // options[String]: static(default) / sticky / hidden
    disableCustomizer: true, // options[Boolean]: true, false(default)
    hideScrollToTop: false, // options[Boolean]: true, false(default)
    disableThemeTour: false, // options[Boolean]: true, false(default)
    menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
    direction: "ltr" // options[String] : ltr / rtl
  };

  state = {
    width: window.innerWidth,
    sidebarState: this.appProps.sidebarCollapsed,
    layout: this.appProps.theme,
    collapsedContent: this.appProps.sidebarCollapsed,       
    sidebarHidden: false,
    currentLang: "en",
    appOverlay: false,
    customizer: false,
    currRoute: this.props.location.pathname
  };

 

  collapsedPaths = [] as any;
  mounted = false;
  updateWidth = () => {
    if (this.mounted) {
      this.setState(prevState => ({
        width: window.innerWidth
      }));
    }
  };

  handleCustomizer = (bool:any)=> {
    this.setState({
      customizer: bool
    });
  };

  componentDidMount() {
    this.mounted = true;
  }

  handleCollapsedMenuPaths = (item:any) => {
    let collapsedPaths = this.collapsedPaths;
    if (!collapsedPaths.includes(item)) {
      collapsedPaths.push(item);
      this.collapsedPaths = collapsedPaths;
    }
  };

  toggleSidebarMenu = (val:any) => {
    this.setState({
      sidebarState: !this.state.sidebarState,
      collapsedContent: !this.state.collapsedContent
    });
  };

  sidebarMenuHover = (val:any) => {
    console.log(val);
    this.setState({
      sidebarState: val
    });
  };

  handleSidebarVisibility = () => {
    console.log(this.mounted);
    if (this.mounted) {
      if (window !== undefined) {
        window.addEventListener("resize", () => {
          if (this.state.sidebarHidden) {
            this.setState({
              sidebarHidden: !this.state.sidebarHidden
            });
          }
        });
      }
      this.setState({
        sidebarHidden: !this.state.sidebarHidden
      });
    }
  };
 
  componentWillUnmount() {
    this.mounted = false;
  }

  handleCurrentLanguage = (lang:any) => {
    this.setState({
      currentLang: lang
    });
  };

  handleAppOverlay = (value:any) => {
    if (value.length > 0) {
      this.setState({
        appOverlay: true
      });
    } else if (value.length < 0 || value === "") {
      this.setState({
        appOverlay: false
      });
    }
  };

  handleAppOverlayClick = () => {
    this.setState({
      appOverlay: false
    });
  };
  
  render() {
    
    let menuThemeArr = [
      "primary",
      "success",
      "danger",
      "info",
      "warning",
      "dark"
    ];
    let sidebarProps = {
      toggleSidebarMenu: this.toggleSidebarMenu,
      toggle: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarHover: this.sidebarMenuHover,
      sidebarVisibility: this.handleSidebarVisibility,
      visibilityState: this.state.sidebarHidden,
      activePath: '',
      collapsedMenuPaths: this.handleCollapsedMenuPaths,
      currentLang: this.state.currentLang,
      activeTheme: this.appProps.menuTheme,
      collapsed: this.state.collapsedContent,
      permission: this.props.permission,
      deviceWidth: this.state.width
    };
    let navbarProps = {
      toggleSidebarMenu: this.toggleSidebarMenu,
      sidebarState: this.state.sidebarState,
      sidebarVisibility: this.handleSidebarVisibility,
      currentLang: this.state.currentLang,
      changeCurrentLang: this.handleCurrentLanguage,
      handleAppOverlay: this.handleAppOverlay,
      appOverlayState: this.state.appOverlay,
      navbarColor: this.appProps.navbarColor,
      navbarType: this.appProps.navbarType
    };
    let footerProps = {
      footerType: this.appProps.footerType,
      hideScrollToTop: this.appProps.hideScrollToTop
    };

    let customizerProps = {
      customizerState: this.state.customizer,
      handleCustomizer: this.handleCustomizer,
      changeMode: this.props.changeMode,
      changeNavbar: this.props.changeNavbarColor,
      changeNavbarType: this.props.changeNavbarType,
      changeFooterType: this.props.changeFooterType,
      changeMenuTheme: this.props.changeMenuColor,
      collapseSidebar: this.props.collapseSidebar,
      hideScrollToTop: this.props.hideScrollToTop,
      activeMode: this.appProps.theme,
      activeNavbar: this.appProps.navbarColor,
      navbarType: this.appProps.navbarType,
      footerType: this.appProps.footerType,
      menuTheme: this.appProps.menuTheme,
      scrollToTop: this.appProps.hideScrollToTop,
      sidebarState: this.appProps.sidebarCollapsed
    };
    return (
      <div
        className={classnames(
          `wrapper vertical-layout theme-${this.appProps.menuTheme}`,
          {
            "menu-collapsed":
              this.state.collapsedContent === true && this.state.width >= 1200,
            "fixed-footer": this.appProps.footerType === "sticky",
            "navbar-static": this.appProps.navbarType === "static",
            "navbar-sticky": this.appProps.navbarType === "sticky",
            "navbar-floating": this.appProps.navbarType === "floating",
            "navbar-hidden": this.appProps.navbarType === "hidden",
            "theme-primary": !menuThemeArr.includes(this.appProps.menuTheme)
          }
        )}
      >
        <Sidebar {...sidebarProps} />
        <div
          className={classnames("app-content content", {
            "show-overlay": this.state.appOverlay === true
          })}
          onClick={this.handleAppOverlayClick}
        >
          <TopNavBar {...navbarProps} />
          <div className="content-wrapper">{this.props.children}</div>
        </div>

        <Footer {...footerProps} />        
        <div
          className="sidenav-overlay"
          onClick={this.handleSidebarVisibility}
        />
      </div>
    );

  }    
}

const mapStateToProps = (state:any) => {
  return {
    app: state.customizer
  };
};
export default VerticalLayout;