import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {PropertyType,SaleType} from '../../../contracts/enums'
import {Property} from '../../../contracts/response-models/property'

import {PropertyRooms} from "./listingfields/property-rooms"
import {PropertyBedrooms} from "./listingfields/property-bedrooms"
import {PropertyFullBath} from "./listingfields/property-fullbaths"
import {PropertyHalfBath} from "./listingfields/property-halfbaths"
import {PropertyGarage} from "./listingfields/property-garage"
import {PropertyYearBuilt} from "./listingfields/property-yearbuilt"
import {PropertyHeatingSource} from "./listingfields/property-heatingsource"
import {PropertyBasement} from "./listingfields/property-basement"
import {PropertyCondition} from "./listingfields/property-condition"
import {PropertyCoolingType} from "./listingfields/property-coolingtype"
import {PropertyExteriorFinish} from "./listingfields/property-exteriorfinish"
import {PropertyStyle} from "./listingfields/property-style"
import {PropertyLotSize} from "./listingfields/property-lotsize"
import {PropertyAcres} from "./listingfields/property-acres"


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsSFHS = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    

        return (     
            <>   
               <Row>
        <PropertyRooms property={property} setProperty={setProperty}/>
  
        <PropertyBedrooms property={property} setProperty={setProperty}/>
  
        <PropertyFullBath property={property} setProperty={setProperty}/>
  
        <PropertyHalfBath property={property} setProperty={setProperty}/>
         
       
        </Row>
          <Row className="mt-2">

          <PropertyGarage property={property} setProperty={setProperty} />
        
          <PropertyYearBuilt property={property} setProperty={setProperty} />
          <PropertyHeatingSource property={property} setProperty={setProperty} />
          <PropertyBasement property={property} setProperty={setProperty} />
        
          
          </Row>
          <Row className="mt-2">   

              <PropertyCondition property={property} setProperty={setProperty} />

               <PropertyCoolingType property={property} setProperty={setProperty} />
               
               <PropertyExteriorFinish property={property} setProperty={setProperty} />
               {(property.propertyType==PropertyType.SFH ) &&
              (
                <PropertyStyle property={property} setProperty={setProperty} />
              )}
                   
                  
            </Row>
            <Row className="mt-2">    
                           
            <PropertyLotSize property={property} setProperty={setProperty} />
  
              
            </Row> 
           
         </>
    )
}
    