import React from "react";
import {
    NavItem,
    NavLink,   
  } from "reactstrap"

import * as Icon from "react-feather"
import { Link } from "react-router-dom"

interface propsType{
    sidebarVisibility:any;
    handleAppOverlay:any;
}

export function NavbarBookmarks(props:propsType) {
    let {        
        sidebarVisibility,
        handleAppOverlay
      } = props
    return(
        <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
            <ul className="navbar-nav d-xl-none">
            <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
            </NavItem>
            </ul>
            <ul className="nav navbar-nav bookmark-icons d-none">
            <NavItem className="nav-item d-none d-lg-block">
                <NavLink>
                <Icon.Star className="text-warning" size={21} />
                </NavLink>
            </NavItem>
            </ul>
        </div>
    )
}

export default NavbarBookmarks