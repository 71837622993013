import React, { useState,useEffect, useRef } from "react";

import { Row, Col, Button,Card, CardBody, ListGroup, ListGroupItem, FormGroup,Progress,Input, Spinner} from "reactstrap"
import { useParams } from "react-router";
import { Link } from "react-router-dom"
import { Edit, Trash, Lock, Check } from "react-feather"
import Breadcrumbs from "../components/@vuexy/breadCrumbs/BreadCrumb"

import {getPropertyInfoService} from "../machines/listing-machine/services"
 
import {Property} from '../contracts/response-models/property'

import {StripePaymentForm} from '../components/Payment/stripe-publish-payment-form'
import { StripePromise } from "../utils/loadStripe";
import { Elements } from "@stripe/react-stripe-js";
 
export const PropertyPublish = () => {
  const [loading,setLoading] = useState(false);
  
    const [property, setProperty] = useState<Property>();
    let { id } = useParams();
    const stripePromise = StripePromise();

    
    useEffect(() => {    
        async function getProperty() {
          setLoading(true);
            if(id){
                const propertyinfo = await getPropertyInfoService(id);  
                setProperty(propertyinfo);  
                
            }
          setLoading(false); 
        }
        getProperty();
    },[id]);


  

    return (
        <React.Fragment>
            <Breadcrumbs breadCrumbTitle="Publish" breadCrumbParent="Property" breadCrumbActive="Publish" />
           
            <Card className="overflow-hidden app-ecommerce-details">
          <CardBody className="pb-0">
          <Row>
        <Col className="mx-auto mb-2 mt-2" md="4" sm="12">
          <h3 className="mb-2 mt-2  text-center">Pay & Publish</h3> 

                <Elements stripe={stripePromise}>
                    {property!=undefined && (
                <StripePaymentForm  property={property} />
                )}
              </Elements>
  </Col>
      </Row> 
          </CardBody>
      
        </Card>
        </React.Fragment>
    )
}
export default PropertyPublish;