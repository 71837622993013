import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import {hasValidToken} from "../utils/has-valid-token";

interface IProps {
  path?:any,
  exact?:any,
  component: any,  
  // any other props that come into the component
}

export default function PrivateRoute({
  component: Component,  
  ...props
}:IProps) {
  
   const signed = hasValidToken();
  if (signed==false) {
    return <Redirect to="/login" />;
  }
  console.log("signed:"+signed);
  
  return (
    <Route
      {...props}
      render={props => (        
        <Component {...props} />        
      )}
    />
  );
}

