import React,{useEffect, useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "..//InputStyles"
import classnames from "classnames";
import Checkbox from "../checkbox/CheckboxesVuexy"
import { Check } from "react-feather"
import {Property} from '../../../contracts/response-models/property'

import {listImprovementsService} from "../../../machines/listing-machine/services"
import { Improvement } from "../../../contracts/response-models/improvement";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyImprovements = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const [improvements, setImprovements] = React.useState<Improvement[]>([]);

    useEffect(() => {    
        async function loadImprovements() {
            const improvements = await listImprovementsService();    
            setImprovements(improvements);              
        }
        loadImprovements();
    },[]);

    const  handleImprovementsChange = (e:any) => {  
        
        var emptyArr = new Array<Improvement>()
       var arrImprovements = property.improvements == undefined ? emptyArr : property.improvements;

       const selectedImprovement = improvements.find(element => element.id== parseInt(e.target.value)) ;
       if(e.target.checked && selectedImprovement){  
         
        arrImprovements.push(selectedImprovement);
            setProperty({...property,improvements:arrImprovements});
        }
        else{        
            const found = arrImprovements.find(element => element.id==selectedImprovement?.id);          
            if(found)     {
                const foundIndex=arrImprovements.indexOf(found,0);
                arrImprovements.splice(foundIndex,1);
                setProperty({...property,improvements:arrImprovements});
            }         
        }    
     
    }

    const SetDefaultChecked=(im:Improvement)=>{     
       // console.log(`property.improvements:${property.improvements?.length},im:${im.id}`)     
        var arrImprovements =  property.improvements;  
        if(arrImprovements!=undefined){    
            const found = property.improvements?.find(element => element.id==im.id);          
            return found!=undefined;      
        } 
        return false;
      }
        return (     
            <>        
           <Row className="mt-2">  
           <Col md="12" sm="12">
        <h4 className="mb-3 mt-2">IMPROVEMENTS </h4>
               </Col>
           {improvements.map(function(object, i){
                    return (
                        <Col md="4" sm="12">
                            <div className="d-inline-block mr-1">
                            <Checkbox color="primary" value={object.id}   onChange={handleImprovementsChange} icon={<Check className="vx-icon" size={16} />} label={object.title} defaultChecked={SetDefaultChecked(object)} />
                       </div>
                        </Col>
                        );
            })}                     
                        
            </Row>     
         </>
    )
}
    