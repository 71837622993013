import jwtDecode from "jwt-decode";

export const hasTokenExpired = (token: string) => {
  if (!token) {
    throw new Error("No token provided!");
  }

  const now = Date.now().valueOf() / 1000;
  const { exp } = jwtDecode(token);

  return now > exp;
};