import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {Property} from '../../../contracts/response-models/property'

import {PropertyLotSize} from "./listingfields/property-lotsize"
import {PropertyAcres} from "./listingfields/property-acres"
import {PropertyTopography} from "./listingfields/property-topography"
import {PropertyWetlands} from "./listingfields/property-wetlands";
import {PropertyLots} from "./listingfields/property-noofLots";
import {PropertyTotalAcres} from "./listingfields/property-totalAcres";
import {PropertySmallestAcres} from "./listingfields/property-smallestacres";
// import {PropertyZone} from "./listingfields/property-zone";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsLND = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
 
        return (     
            <>   
               <Row>


        <PropertyAcres property={property} setProperty={setProperty}/>
        <PropertyTotalAcres property={property} setProperty={setProperty}/>
        <PropertySmallestAcres property={property} setProperty={setProperty}/>
        <PropertyLots property={property} setProperty={setProperty}/>
      
   
       
        </Row>
          <Row className="mt-2">
          <PropertyTopography property={property} setProperty={setProperty}/>
          <PropertyWetlands property={property} setProperty={setProperty}/> 
         
         
                  
          </Row>
          <Row className="mt-2">
          <PropertyLotSize property={property} setProperty={setProperty} />
            </Row>

           
         </>
    )
}
    