import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {PropertyType,SaleType} from '../../../contracts/enums'
import {Property} from '../../../contracts/response-models/property'


import {PropertyListPrice} from "./listingfields/property-listprice"
import {PropertyAvailableDate} from "./listingfields/property-availableDate"
import {PropertyIsSecurityDeposit} from "./listingfields/property-isSecurityDeposit"
import {PropertyIsBrokerFees} from "./listingfields/property-brokerFees"
import {PropertyLeaseTerm} from "./listingfields/property-leaseTerm"
import {PropertyStartDate} from "./listingfields/property-startDate"
import {PropertyExpiryDate} from "./listingfields/property-enddate"

interface PricingStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyPriceRent = ({ property, setProperty}:PricingStepProps) => {      

        return (     
                <>
                    <PropertyListPrice property={property} setProperty={setProperty}/>    
                    <PropertyIsBrokerFees property={property} setProperty={setProperty}/>       
                    <PropertyIsSecurityDeposit property={property} setProperty={setProperty}/>
                    <PropertyLeaseTerm property={property} setProperty={setProperty}/>  
                    <PropertyAvailableDate property={property} setProperty={setProperty}/>  
                    <PropertyStartDate property={property} setProperty={setProperty}/>
                    <PropertyExpiryDate property={property} setProperty={setProperty}/>
              </>
            
    )
}
    