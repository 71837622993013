import React,{useState,useEffect} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup,
    ModalHeader,
    ModalBody,
    Spinner,
    Button,
   
} from "reactstrap"

import {Property} from '../../contracts/response-models/property'
import { PropertyHeadInfo } from "./property-head-info-modal";

import { VirtualTour } from '../../contracts/response-models/virtualtour'
import { ToastContainer, toast } from "react-toastify";

import { saveVirtualTourService } from '../../machines/listing-machine/services'

interface PropertyIdProps {  
    property?:Property,
    toggle:any; 
    readyState:any;
  setReadyState:any; 
}


export const PropertyVLModal = ({ property,toggle,readyState,setReadyState}:PropertyIdProps) => {  

  const [loading, setLoading] = useState(false);
  const [virtualTour, setVirtualTour] = useState<VirtualTour>(
    { vTour:property?.virtualTLink}
  );
  const handleVirtualLinkChange = (e: any) => {
    setVirtualTour({ ...virtualTour, vTour: e.target.value });
  }



  const saveVirtualTour = async (e: any) => {
   e.preventDefault()
    var isValidated = true;

    if (isValidated == true) {
      setLoading(true);

      const response = await saveVirtualTourService(virtualTour, property?.id);
      toast.success("Virtual Tour Info Saved  Successfully", {
        position: toast.POSITION.TOP_RIGHT
      })

      setLoading(false);
      toggle();
      setReadyState(true);
    }

  }


  
  
        return (     
          <>   
          <ModalHeader toggle={toggle}  >
          Virtual Tour Link
          </ModalHeader>
          <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-between mt-1">
            <Spinner size="lg" className="primary float-right" />
          </div>
        ) : (
            <>
                <PropertyHeadInfo property={property}    />
 
              <FormGroup>
                <Label for="address">Place your video or virtual link here</Label>
              
                   <Input
                  type="text"
                  id="virtualTourLink"
                  placeholder="Virtual Tour Link"
                  value={virtualTour?.vTour}
                  onChange={handleVirtualLinkChange}
                />


              </FormGroup>
              <FormGroup>

                <Row className="mb-2 mt-0">
                  <Col md="4" sm="6" className="text-right">

                  </Col>
                  <Col md="4" sm="6" className="text-right">
                    <Button
                      color="primary"
                      type="submit"
                      className="mr-1 mb-1"
                      onClick={saveVirtualTour}

                    >
                      Save
                </Button>
                  </Col>
                  <Col md="4" sm="6" className="text-right">
                  </Col>
                </Row>

              </FormGroup>
            </>
          )}
      </ModalBody>           
         </>
    )
}
    