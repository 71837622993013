import React,{useEffect, useState} from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "..//InputStyles"
import classnames from "classnames";
import Checkbox from "../checkbox/CheckboxesVuexy"
import { Check } from "react-feather"
import {Property} from '../../../contracts/response-models/property'

import {listZonesService} from "../../../machines/listing-machine/services"
import { Zone } from "../../../contracts/response-models/zone";


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyZones = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    
    const [zones, setZones] = React.useState<Zone[]>([]);

    useEffect(() => {    
        async function loadZones() {
            const zones = await listZonesService();    
            setZones(zones);              
        }
        loadZones();
    },[]);

    const  handleZonesChange = (e:any) => {  
       
        var emptyArr = new Array<Zone>()
        var arrZones = property.zones == undefined ? emptyArr : property.zones;
 
        const selectedZone = zones.find(element => element.id== parseInt(e.target.value)) ;
        if(e.target.checked && selectedZone){  
          
            arrZones.push(selectedZone);
             setProperty({...property,zones:arrZones});
         }
         else{        
             const found = arrZones.find(element => element.id==selectedZone?.id);          
             if(found)     {
                 const foundIndex=arrZones.indexOf(found,0);
                 arrZones.splice(foundIndex,1);
                 setProperty({...property,zones:arrZones});
             }         
         } 
      }
      //console.log("zone section pid :"+property?.id);
      const SetDefaultChecked=(zn:Zone)=>{ 
        //    console.log(`property.zones:${property.zones?.length},zn:${zn.id}`)        
        var arrZones =  property.zones;  
        if(arrZones!=undefined){    
            const found = property.zones?.find(element => element.id==zn.id);          
            return found!=undefined;      
        } 
       
        return false;
      }
        return (     
            <>        
           <Row className="mt-2">  
           <Col md="12" sm="12">
           <h4 className="mb-3 mt-2">CURRENT ZONING & PERMITTED USE</h4>
               </Col>
           {zones.map(function(object, i){
                    return (
                        <Col md="4" sm="12">
                            <div className="d-inline-block mr-1">
                            <Checkbox color="primary" value={object.id}   onChange={handleZonesChange} icon={<Check className="vx-icon" size={16} />} label={object.title} defaultChecked={SetDefaultChecked(object)} />
                       </div>
                        </Col>
                        );
            })}                     
                        
            </Row>     
         </>
    )
}
    