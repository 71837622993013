import React from "react";
import {
    Label,
    Row,
    Col,   
    Input,
    CustomInput,
    FormGroup
} from "reactstrap"
import NumericInput from "react-numeric-input"
import { mobileStyle } from "../InputStyles"
import classnames from "classnames";
import {Property} from '../../../contracts/response-models/property'

import {PropertyBedrooms} from "./listingfields/property-bedrooms"
import {PropertyFullBath} from "./listingfields/property-fullbaths"
import {PropertyHalfBath} from "./listingfields/property-halfbaths"
import {PropertyPets} from "./listingfields/property-pets"
import {PropertyGarage} from "./listingfields/property-garage"
import {PropertyYearBuilt} from "./listingfields/property-yearbuilt"
import {PropertyHeatingSource} from "./listingfields/property-heatingsource"
import {PropertyBasement} from "./listingfields/property-basement"
import {PropertyCondition} from "./listingfields/property-condition"
import {PropertyCoolingType} from "./listingfields/property-coolingtype"


interface PropertyDetailsStepProps {
    property:Property,
    setProperty:any;    
}
export const PropertyDetailsSFHR = ({ property, setProperty}:PropertyDetailsStepProps) => {   
    

        return (     
            <>   
        <Row>
       
        <PropertyBedrooms property={property} setProperty={setProperty}/>
  
        <PropertyFullBath property={property} setProperty={setProperty}/>
  
        <PropertyHalfBath property={property} setProperty={setProperty}/>
         
        <PropertyPets property={property} setProperty={setProperty}/>
        
       
        </Row>
          <Row className="mt-2">

          <PropertyGarage property={property} setProperty={setProperty} />
        
          <PropertyYearBuilt property={property} setProperty={setProperty} />
          <PropertyHeatingSource property={property} setProperty={setProperty} />
        
          <PropertyCondition property={property} setProperty={setProperty} />

          
          </Row>
          <Row className="mt-2">   

          <PropertyBasement property={property} setProperty={setProperty} />
          <PropertyCoolingType property={property} setProperty={setProperty} />

            </Row>
       
           
         </>
    )
}
    