import {
    getTokenFromLocalStorage
} from "../api/client";

import {hasTokenExpired} from "./has-token-expired"

export const hasValidToken = () => {
    
  
    const storageToken = getTokenFromLocalStorage();  
    if (storageToken) {
        if(hasTokenExpired(storageToken.token))
            return false;
        else
            return true;
    }
    return false;
};