import React from "react";
import Select from "react-select"

const dataOptions = [
    { value: undefined, label: "Select One" },
    { value: 1, label: "Vinyl/Aluminium" },
    { value: 2, label: "Asbestos" },
    { value: 3, label: "Shingles" },
    { value: 4, label: "Clapboard" },
    { value: 5, label: "Brick/Stone" },
    { value: 6, label: "Brick/Stone Faced"},
    { value: 7, label: "Mason/Stucco" },
    { value: 8, label: "Other" }
]

export const SelectExtFinishInput = (props:any) => {  
    const defaultItem = dataOptions.filter(option => option.value === props.defaultValue)
    return (     
            <Select 
                {...props}                
                classNamePrefix="select"
                value={defaultItem}          
                options={dataOptions}
            />            
    )
}
export default SelectExtFinishInput;