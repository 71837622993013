import { client } from "../client";
import { ValidateTokenResponse } from "../../contracts/response-models/validate-token";
import { ValidateTokenRequest } from "../../contracts/request-models/validate-token";

function validateToken(token: string) {
  const request: ValidateTokenRequest = {
    token,
  };

  return client<ValidateTokenResponse>("/token/validate", request);
}

export { validateToken };