import React from "react";
import { Button } from "reactstrap"
import { Heart, ArrowUp } from "react-feather"

interface FooterProps {
    hideScrollToTop: boolean   
}


export function Footer(props: FooterProps) {
    let footerTypeArr = ["sticky", "static", "hidden"]
    return (
        <footer
        className="footer footer-light footer-static"
      >
        <p className="mb-0 clearfix">
          <span className="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT © {new Date().getFullYear()}
            <a
              href="https://www.zaglist.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Zaglist,
            </a>
            All rights reserved
          </span>          
        </p>
        {props.hideScrollToTop === false ? (          
            <Button color="primary" className="btn-icon scroll-top">
              <ArrowUp size={15} />
            </Button>          
        ) : null}
      </footer>
    )
}


  
export default Footer
  